export enum SubmodulesEnum {
  ProductAndSegment = 'Product and Segment',
  TypeAndFunction = 'Type and function',
  PersonalBankingFTE = 'Personal Banking FTE',
  Customers = 'Customers',
  ProductSales = 'Product Sales',
  OnlineAndMobileCustomers = 'Online and Mobile Customers',
  BranchAndATMCustomers = 'Branch and ATM Customers',
  ContactCenter = 'Call Center',
}
