import { AccountCircle, Help, Menu as MenuIcon } from '@mui/icons-material';
import {
  AppBar,
  Box,
  Dialog,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import React, { Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ChatBox from '../Chat/ChatBox';

import RebexLogoIcon from '../../../../assets/icons/RebexLogo';
import { useAppSelector } from '../../../../utils/hooks/useAppSelector';
import { clearAuth, selectUserRoles } from '../../../../utils/redux/authSlice';
import chatai from '../../../../assets/svg/robot.svg';
import { rebexApi } from '../../../../utils/redux/endpoints/baseRebexEndpoints';
import { extendedExcelApi } from '../../../../utils/redux/endpoints/excelEndpoints';
import { keyThemesEndpoints } from '../../../../utils/redux/endpoints/keyThemesEndpoints';
import { modulesEndpoints } from '../../../../utils/redux/endpoints/modulesEndpoints';
import { peerGroupsExtendedApi } from '../../../../utils/redux/endpoints/peerGroupsEndpoints';
import { reportsExtendedApi } from '../../../../utils/redux/endpoints/reportsEndpoints';
import { submodulesExtendedApi } from '../../../../utils/redux/endpoints/submodulesEndpoints';
import { usersExtendedApi } from '../../../../utils/redux/endpoints/usersEndpoints';
import { workspaceDocumentsExtendedApi } from '../../../../utils/redux/endpoints/workspaceDocumentsEndpoints';
import { workspacesExtendedApi } from '../../../../utils/redux/endpoints/workspacesEndpoints';

interface Props {
  handleOpenSideMenu: () => void;
}

const helpMenuItems = [
  { title: 'How to Use REBEX', path: 'resources/how-to-use' },
  { title: 'Glossary', path: 'resources/glossary' },
  { title: 'FAQ', path: 'resources/faq' },
];

const userMenuItems = [
  { title: 'My Profile', path: 'my-profile' },
  { title: 'Sign Out', path: '/', signOut: true },
];

const Topbar: React.FC<Props> = ({ handleOpenSideMenu }) => {
  const { palette } = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userRoles = useAppSelector((state) => selectUserRoles(state));
  const [helpAnchorEl, setHelpAnchorEl] = useState<null | HTMLElement>(null);
  const [userAnchorEl, setUserAnchorEl] = useState<null | HTMLElement>(null);
  const [isChatDialogOpen, setIsChatDialogOpen] = useState<boolean>(false);
  const [isChatBoxFullScreen, setIsChatBoxFullScreen] = useState<boolean>(false);

  const handleSignOut = () => {
    sessionStorage.clear();
    dispatch(clearAuth());

    dispatch(rebexApi.util.resetApiState());
    dispatch(usersExtendedApi.util.resetApiState());
    dispatch(peerGroupsExtendedApi.util.resetApiState());
    dispatch(reportsExtendedApi.util.resetApiState());
    dispatch(workspacesExtendedApi.util.resetApiState());
    dispatch(workspaceDocumentsExtendedApi.util.resetApiState());
    dispatch(submodulesExtendedApi.util.resetApiState());
    dispatch(keyThemesEndpoints.util.resetApiState());
    dispatch(modulesEndpoints.util.resetApiState());
    dispatch(extendedExcelApi.util.resetApiState());

    navigate('/');
  };

  return (
    <>
      <AppBar position="static" sx={{ height: '48px', bgcolor: palette.green[700] }}>
        <Toolbar variant="dense">
          {userRoles.includes('admin') ? (
            <IconButton onClick={handleOpenSideMenu} size="medium" edge="start" color="inherit">
              <MenuIcon />
            </IconButton>
          ) : null}

          <Box flexGrow={1} display="flex" justifyContent="center" alignItems="center" gap={1}>
            <IconButton
              onClick={() => {
                if (userRoles.includes('admin') || userRoles.includes('data_lead')) {
                  navigate('/app');
                }
              }}
            >
              <RebexLogoIcon sx={{ height: 32, mr: 1 }} />
              <Typography sx={{ fontSize: '13.89px', fontWeight: 700, color: palette.green[400] }}>REBEX</Typography>
            </IconButton>
          </Box>

          <Box display="flex" gap={2}>
            <Tooltip title="Chat with Genie">
              <img
                src={chatai}
                alt="chatai"
                style={{ height: '25px', width: '25px', cursor: 'pointer' }}
                onClick={() => {
                  setIsChatDialogOpen(true);
                }}
              />
            </Tooltip>
            <IconButton
              sx={{ p: 0 }}
              color="inherit"
              onClick={(e) => {
                setUserAnchorEl(e.currentTarget);
              }}
            >
              <AccountCircle />
            </IconButton>
            <IconButton
              sx={{ p: 0 }}
              color="inherit"
              onClick={(e) => {
                setHelpAnchorEl(e.currentTarget);
              }}
            >
              <Help />
            </IconButton>
          </Box>

          <Menu
            id="dropdown-menu"
            anchorEl={userAnchorEl}
            open={Boolean(userAnchorEl)}
            onClose={() => {
              setUserAnchorEl(null);
            }}
            slotProps={{ paper: { sx: { borderRadius: '4px' } } }}
          >
            {userMenuItems.map((user) => (
              <MenuItem
                key={user.title}
                onClick={() => {
                  setUserAnchorEl(null);
                  if (user.signOut ?? false) {
                    handleSignOut();
                  } else {
                    navigate(user.path);
                  }
                }}
              >
                {user.title}
              </MenuItem>
            ))}
          </Menu>

          <Menu
            id="dropdown-menu"
            anchorEl={helpAnchorEl}
            open={Boolean(helpAnchorEl)}
            onClose={() => {
              setHelpAnchorEl(null);
            }}
            slotProps={{ paper: { sx: { borderRadius: '4px' } } }}
          >
            {helpMenuItems.map((helpItem) => (
              <MenuItem
                key={helpItem.title}
                onClick={() => {
                  setUserAnchorEl(null);
                  navigate(helpItem.path);
                }}
              >
                {helpItem.title}
              </MenuItem>
            ))}
          </Menu>
        </Toolbar>
      </AppBar>
      <Dialog
        fullWidth
        fullScreen={isChatBoxFullScreen}
        maxWidth="xl"
        open={isChatDialogOpen}
        onClose={() => {
          setIsChatDialogOpen(false);
        }}
      >
        <ChatBox
          setIsChatDialogOpen={setIsChatDialogOpen}
          setIsChatBoxFullScreen={setIsChatBoxFullScreen}
          isChatBoxFullScreen={isChatBoxFullScreen}
        />
      </Dialog>
    </>
  );
};
export default Topbar;
