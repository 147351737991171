import { Box, useTheme } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useWorkspaceDataContext } from '../../../../../../../../../context/WorkspaceDataContext';
import { useAppSelector } from '../../../../../../../../../utils/hooks/useAppSelector';
import {
  useGetDataTableQuery,
  useLazyGetAllCommentThreadsByDataTableQuery,
  useLazyGetIssuesQuery,
} from '../../../../../../../../../utils/redux/api';
import {
  addDataTable,
  selectDataTableByTabKeyAndTable,
  updateDataTable,
} from '../../../../../../../../../utils/redux/dataTablesSlice';
import { CustomersTables } from '../../../../../../../../../utils/types/DataTablesEnum';
import TableComponent, {
  type Category,
  type Column,
} from '../../../../../../../../DataTables/TableComponent/TableComponent';
import { commentThreadsToCellSet } from '../../../../../../../../DataTables/utils/commentThreadsToCellSet';
import { getCommentThreads, getIssues } from '../../../../../../../../DataTables/utils/getCommentThreads';
import { formatNumber } from '../../../../../../../../DataTables/utils/numberFormat';
import { sumValues } from '../../../../../../../../DataTables/utils/sumValues';
import { handleOpenComments } from '../../utils/handleOpenComments';

const CustomerProductHolding: React.FC = () => {
  const { palette } = useTheme();
  const tabKey = useAppSelector((state) => state.dataTables.activeTabKey);
  const { submoduleId: stringSubmoduleId } = useParams();
  const tableSubmoduleId = Number(stringSubmoduleId);
  const dispatch = useDispatch();
  const dataTable = useAppSelector((state) =>
    selectDataTableByTabKeyAndTable(state, tabKey, CustomersTables.CustomerProductHolding),
  );

  const isTotalCalculated = useRef(false);

  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  const { activeWorkspace: workspace } = useWorkspaceDataContext();
  const fetchFlag = useAppSelector((state) => state.fetchFlags.refetch);
  const {
    data: dataTableResponse,
    isLoading: isLoadingDataTable,
    refetch,
  } = useGetDataTableQuery({
    submodule_id: tableSubmoduleId,
    table: CustomersTables.CustomerProductHolding,
  });
  const [fetchDataTableCommentThreads] = useLazyGetAllCommentThreadsByDataTableQuery();
  const [fetchDataTableIssues] = useLazyGetIssuesQuery();

  const categories = useRef<Category[]>([
    { label: 'Deposits', editable: true, indentLevel: 1 },
    { label: 'Checking accounts', editable: true, indentLevel: 2 },
    { label: 'Savings accounts', editable: true, indentLevel: 2 },
    { label: 'of which money market', editable: true, indentLevel: 3 },
    { label: 'Term deposits', editable: true, indentLevel: 2 },
    { label: 'Credit', editable: true, indentLevel: 1 },
    { label: 'Overdrafts', editable: true, indentLevel: 2 },
    { label: 'Credit cards', editable: true, indentLevel: 2 },
    { label: 'Unsecured consumer loans', editable: true, indentLevel: 2 },
    { label: 'Secured consumer loans', editable: true, indentLevel: 2 },
    { label: 'Mortgages total', editable: true, indentLevel: 2 },
    { label: 'of which new mortgages', editable: true, indentLevel: 3 },
    { label: 'of which mortage refinances', editable: true, indentLevel: 3 },
    { label: 'Home equity', editable: true, indentLevel: 2 },
    { label: 'Investments', editable: true, indentLevel: 1 },
    { label: 'Insurance', editable: true, indentLevel: 1 },
    { label: 'Other', editable: true, indentLevel: 1 },
    { label: 'Total', editable: false, indentLevel: 1 },
  ]);
  const columns = useRef<Column[]>([
    { key: 'A', label: 'Total accounts/products (#)', editable: true },
    { key: 'B', label: 'Retail customers with product (#)', editable: true },
    { key: 'C', label: 'New-to-bank customers with product (#)', editable: true },
    { key: 'D', label: 'Primary customers with product (#)', editable: true },
    { key: 'E', label: 'Checking account customers with product (#)', editable: true },
    { key: 'F', label: 'Mass market customers with product (#)', editable: true },
    { key: 'G', label: 'Affluent customers with product (#)', editable: true },
  ]);

  useEffect(() => {
    if (!isLoadingDataTable) {
      dispatch(
        addDataTable({
          dataTable: {
            id: dataTableResponse?.id ?? 0,
            commentCount: dataTableResponse?.comments_amount ?? 0,
            submodule_id: tableSubmoduleId,
            tabKey,
            table: CustomersTables.CustomerProductHolding,
            data: dataTableResponse?.data ?? [],
            columns: dataTableResponse?.columns ?? [],
            rows: dataTableResponse?.rows ?? [],
          },
          columns: ['A', 'B', 'C', 'D', 'E', 'F', 'G'],
          rowCount: 18,
          totalColumns: ['A1', 'A6', 'A18'],
        }),
      );
      // to update the auto calculated values
      isTotalCalculated.current = false;
      dataTableResponse?.rows.forEach((rowName, index) => {
        categories.current[index].rowName = rowName;
      });

      dataTableResponse?.columns?.forEach((columnName, index) => {
        columns.current[index].columnName = columnName;
      });
    }
  }, [dispatch, dataTableResponse, isLoadingDataTable, tableSubmoduleId, tabKey]);

  useEffect(() => {
    refetch().catch((err) => {
      console.error(err);
    });
  }, [fetchFlag, refetch]);

  useEffect(() => {
    if (dataTable === undefined) return;
    if (!isTotalCalculated.current) {
      const values = { ...dataTable?.values };
      const updatedValues = calculateTotals(values as Record<string, number | null>);

      dispatch(
        updateDataTable({
          dataTable: {
            tabKey,
            table: CustomersTables.CustomerProductHolding,
            values: updatedValues,
          },
          columns: ['A', 'B', 'C', 'D', 'E', 'F', 'G'],
          rowCount: 18,
        }),
      );

      isTotalCalculated.current = true;
    }
    void getCommentThreads({
      dataTableId: dataTable.id,
      fetchDataTableCommentThreads,
      dispatch,
    });

    void getIssues({
      dataTableId: dataTable.id,
      submoduleId: tableSubmoduleId,
      dispatch,
      fetchDataTableIssues,
    });
  }, [dataTable, dispatch, fetchDataTableCommentThreads, fetchDataTableIssues, tableSubmoduleId]);

  const calculateTotals = (values: Record<string, number | null>) => {
    const a1 = formatNumber(sumValues(['A2', 'A3', 'A5'], values));
    const a6 = formatNumber(sumValues(['A7', 'A8', 'A9', 'A10', 'A14'], values));
    const a18 = formatNumber(a1 + a6 + sumValues(['A15', 'A16', 'A17'], values));

    return {
      ...values,
      A1: a1,
      A6: a6,
      A18: a18,
    };
  };

  const isCellDisabled = (category: Category, column: Column) => {
    if (category.label === 'Other' && column.label === 'Retail customers with product (#)') {
      return true;
    }
    if (category.label === 'Other' && column.label === 'New-to-bank customers with product (#)') {
      return true;
    }
    if (category.label === 'Other' && column.label === 'Primary customers with product (#)') {
      return true;
    }
    if (category.label === 'Other' && column.label === 'Checking account customers with product (#)') {
      return true;
    }
    if (category.label === 'Other' && column.label === 'Mass market customers with product (#)') {
      return true;
    }
    if (category.label === 'Other' && column.label === 'Affluent customers with product (#)') {
      return true;
    }
    if (category.label === 'Total' && column.label === 'Retail customers with product (#)') {
      return true;
    }
    if (category.label === 'Total' && column.label === 'New-to-bank customers with product (#)') {
      return true;
    }
    if (category.label === 'Total' && column.label === 'Primary customers with product (#)') {
      return true;
    }
    if (category.label === 'Total' && column.label === 'Checking account customers with product (#)') {
      return true;
    }
    if (category.label === 'Total' && column.label === 'Mass market customers with product (#)') {
      return true;
    }
    if (category.label === 'Total' && column.label === 'Affluent customers with product (#)') {
      return true;
    }

    return false;
  };

  const customTextFieldStyle = (category: Category, column: Column) => {
    if (category.label === 'Deposits' && column.label === 'Total accounts/products (#)') {
      return {
        backgroundColor: palette.blue[200],
      };
    }
    if (category.label === 'Credit' && column.label === 'Total accounts/products (#)') {
      return {
        backgroundColor: palette.blue[200],
      };
    }
    return {};
  };

  const handleClick = (cellId: string, column: string, row: string) => {
    if (timer === null) {
      const newTimer = setTimeout(() => {
        setTimer(null);
      }, 250);
      setTimer(newTimer);
    } else {
      clearTimeout(timer);
      setTimer(null);
      handleOpenComments(dispatch, dataTable?.id, { cellId, column, row });
    }
  };

  const handleValueChange = (cellId: string, newValue: number | null) => {
    const values = { ...dataTable?.values, [cellId]: newValue };
    const updatedValues = calculateTotals(values as Record<string, number | null>);

    dispatch(
      updateDataTable({
        dataTable: {
          tabKey,
          table: CustomersTables.CustomerProductHolding,
          values: updatedValues,
        },
        columns: ['A', 'B', 'C', 'D', 'E', 'F', 'G'],
        rowCount: 18,
      }),
    );
  };

  return (
    <Box>
      <Box>
        <TableComponent
          categories={categories.current}
          columns={columns.current}
          values={dataTable?.values ?? {}}
          onValueChange={handleValueChange}
          onClickCell={handleClick}
          onRightClickCell={(cellId, column, row) => {
            handleOpenComments(dispatch, dataTable?.id, { cellId, column, row });
          }}
          banner={true}
          bannerValue={`${workspace!.end_of_period}`}
          getRowStyle={(category) => {
            if (category.label === 'Total') {
              return { backgroundColor: palette.blue[300] };
            }
          }}
          isCellDisabled={isCellDisabled}
          customTextFieldStyle={customTextFieldStyle}
          cellsWithCommentsSet={commentThreadsToCellSet(dataTable?.commentThreads)}
          issues={dataTable?.issues ?? []}
        />
      </Box>
    </Box>
  );
};

export default CustomerProductHolding;
