import { Box } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useWorkspaceDataContext } from '../../../../../../../../../context/WorkspaceDataContext';
import { useAppSelector } from '../../../../../../../../../utils/hooks/useAppSelector';
import {
  useGetDataTableQuery,
  useLazyGetAllCommentThreadsByDataTableQuery,
  useLazyGetIssuesQuery,
} from '../../../../../../../../../utils/redux/api';
import {
  addDataTable,
  selectDataTableByTabKeyAndTable,
  updateDataTable,
} from '../../../../../../../../../utils/redux/dataTablesSlice';
import { TypeAndFunctionTables } from '../../../../../../../../../utils/types/DataTablesEnum';
import TableComponent, {
  type Category,
  type Column,
} from '../../../../../../../../DataTables/TableComponent/TableComponent';
import { commentThreadsToCellSet } from '../../../../../../../../DataTables/utils/commentThreadsToCellSet';
import { getCommentThreads, getIssues } from '../../../../../../../../DataTables/utils/getCommentThreads';
import { formatNumber } from '../../../../../../../../DataTables/utils/numberFormat';
import { sumValues } from '../../../../../../../../DataTables/utils/sumValues';
import { handleOpenComments } from '../../utils/handleOpenComments';

const MarketingCosts: React.FC = () => {
  const tabKey = useAppSelector((state) => state.dataTables.activeTabKey);
  const { submoduleId: stringSubmoduleId } = useParams();
  const tableSubmoduleId = Number(stringSubmoduleId);
  const dispatch = useDispatch();
  const dataTable1 = useAppSelector((state) =>
    selectDataTableByTabKeyAndTable(state, tabKey, TypeAndFunctionTables.MarketingCostsTable1TotalMarketing),
  );
  const dataTable2 = useAppSelector((state) =>
    selectDataTableByTabKeyAndTable(
      state,
      tabKey,
      TypeAndFunctionTables.MarketingCostsTable2DigitalChannelsMarketingSpend,
    ),
  );
  const dataTable3 = useAppSelector((state) =>
    selectDataTableByTabKeyAndTable(state, tabKey, TypeAndFunctionTables.MarketingCostsTable3SalesAndPromotion),
  );

  const isTotalCalculated1 = useRef(false);
  const isTotalCalculated2 = useRef(false);
  const isTotalCalculated3 = useRef(false);

  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  const { activeWorkspace: workspace } = useWorkspaceDataContext();
  const fetchFlag = useAppSelector((state) => state.fetchFlags.refetch);
  const {
    data: dataTableResponse1,
    isLoading: isLoadingDataTable1,
    refetch: refetchDataTable1,
  } = useGetDataTableQuery({
    submodule_id: tableSubmoduleId,
    table: TypeAndFunctionTables.MarketingCostsTable1TotalMarketing,
  });
  const {
    data: dataTableResponse2,
    isLoading: isLoadingDataTable2,
    refetch: refetchDataTable2,
  } = useGetDataTableQuery({
    submodule_id: tableSubmoduleId,
    table: TypeAndFunctionTables.MarketingCostsTable2DigitalChannelsMarketingSpend,
  });

  const {
    data: dataTableResponse3,
    isLoading: isLoadingDataTable3,
    refetch: refetchDataTable3,
  } = useGetDataTableQuery({
    submodule_id: tableSubmoduleId,
    table: TypeAndFunctionTables.MarketingCostsTable3SalesAndPromotion,
  });
  const [fetchDataTableCommentThreads] = useLazyGetAllCommentThreadsByDataTableQuery();
  const [fetchDataTableIssues] = useLazyGetIssuesQuery();

  const table1Categories = useRef<Category[]>([
    { label: 'Marketing spend by channel', editable: false, indentLevel: 1 },
    { label: 'of which Branch channels', editable: true, indentLevel: 2 },
    { label: 'of which Digital channels', editable: true, indentLevel: 2 },
    { label: 'of which Other channels', editable: true, indentLevel: 2 },
  ]);
  const table1Columns = useRef<Column[]>([{ key: 'A', label: 'Total cost ($)', editable: true }]);

  const table2Categories = useRef<Category[]>([
    { label: 'Digital channels marketing spend', editable: false, indentLevel: 1 },
    { label: 'of which social media', editable: true, indentLevel: 2 },
    { label: 'of which web (SEM/SEO)', editable: true, indentLevel: 2 },
    { label: 'of which email boost', editable: true, indentLevel: 2 },
    { label: 'of which display', editable: true, indentLevel: 2 },
    { label: 'other', editable: true, indentLevel: 2 },
  ]);
  const table2Columns = useRef<Column[]>([{ key: 'A', label: 'Total cost ($)', editable: true }]);

  const table3Categories = useRef<Category[]>([
    { label: 'Marketing spend on sales promotion', editable: false, indentLevel: 1 },
    { label: 'of which Branch channels', editable: true, indentLevel: 2 },
    { label: 'of which Digital channels', editable: true, indentLevel: 2 },
    { label: 'of which Other channels', editable: true, indentLevel: 2 },
  ]);
  const table3Columns = useRef<Column[]>([
    { key: 'A', label: 'Total cost ($)', editable: true },
    { key: 'B', label: 'Offers sent (#)', editable: true },
  ]);

  useEffect(() => {
    if (!isLoadingDataTable1) {
      dispatch(
        addDataTable({
          dataTable: {
            id: dataTableResponse1?.id ?? 0,
            commentCount: dataTableResponse1?.comments_amount ?? 0,
            submodule_id: tableSubmoduleId,
            table: TypeAndFunctionTables.MarketingCostsTable1TotalMarketing,
            tabKey,
            data: dataTableResponse1?.data ?? [],
            columns: dataTableResponse1?.columns ?? [],
            rows: dataTableResponse1?.rows ?? [],
          },
          columns: ['A'],
          rowCount: 4,
          totalColumns: ['A1'],
        }),
      );
    }
    // to update the auto calculated values
    isTotalCalculated1.current = false;

    dataTableResponse1?.rows.forEach((rowName, index) => {
      table1Categories.current[index].rowName = rowName;
    });

    dataTableResponse1?.columns?.forEach((columnName, index) => {
      table1Columns.current[index].columnName = columnName;
    });

    if (!isLoadingDataTable2) {
      dispatch(
        addDataTable({
          dataTable: {
            id: dataTableResponse2?.id ?? 0,
            commentCount: dataTableResponse2?.comments_amount ?? 0,
            submodule_id: tableSubmoduleId,
            table: TypeAndFunctionTables.MarketingCostsTable2DigitalChannelsMarketingSpend,
            tabKey,
            data: dataTableResponse2?.data ?? [],
            columns: dataTableResponse2?.columns ?? [],
            rows: dataTableResponse2?.rows ?? [],
          },
          columns: ['A'],
          rowCount: 6,
          totalColumns: ['A1'],
        }),
      );
      // to update the auto calculated values
      isTotalCalculated2.current = false;

      dataTableResponse2?.rows.forEach((rowName, index) => {
        table2Categories.current[index].rowName = rowName;
      });

      dataTableResponse2?.columns?.forEach((columnName, index) => {
        table2Columns.current[index].columnName = columnName;
      });
    }

    if (!isLoadingDataTable3) {
      dispatch(
        addDataTable({
          dataTable: {
            id: dataTableResponse3?.id ?? 0,
            commentCount: dataTableResponse3?.comments_amount ?? 0,
            submodule_id: tableSubmoduleId,
            table: TypeAndFunctionTables.MarketingCostsTable3SalesAndPromotion,
            tabKey,
            data: dataTableResponse3?.data ?? [],
            columns: dataTableResponse3?.columns ?? [],
            rows: dataTableResponse3?.rows ?? [],
          },
          columns: ['A', 'B'],
          rowCount: 4,
        }),
      );
      // to update the auto calculated values
      isTotalCalculated3.current = false;
    }
  }, [
    dispatch,
    dataTableResponse1,
    dataTableResponse2,
    dataTableResponse3,
    isLoadingDataTable1,
    isLoadingDataTable2,
    isLoadingDataTable3,
    tableSubmoduleId,
    tabKey,
  ]);

  useEffect(() => {
    const refetchAll = async () => {
      try {
        await Promise.all([refetchDataTable1(), refetchDataTable2(), refetchDataTable3()]);
      } catch (error) {
        console.log('Error in fetching data');
      }
    };

    refetchAll().catch((err) => {
      console.log(err);
    });
  }, [fetchFlag, refetchDataTable1, refetchDataTable2, refetchDataTable3]);

  useEffect(() => {
    if (dataTable1 === undefined) return;
    if (!isTotalCalculated1.current) {
      const table1Values = { ...dataTable1?.values };
      const table1UpdatedValues = calculateTable1Totals(table1Values as Record<string, number | null>);

      dispatch(
        updateDataTable({
          dataTable: {
            tabKey,
            table: TypeAndFunctionTables.MarketingCostsTable1TotalMarketing,
            values: table1UpdatedValues,
          },
          columns: ['A'],
          rowCount: 4,
        }),
      );

      isTotalCalculated1.current = true;
    }
    void getCommentThreads({
      dataTableId: dataTable1.id,
      fetchDataTableCommentThreads,
      dispatch,
    });

    void getIssues({
      dataTableId: dataTable1.id,
      submoduleId: tableSubmoduleId,
      dispatch,
      fetchDataTableIssues,
    });
  }, [dataTable1, dispatch, fetchDataTableCommentThreads, fetchDataTableIssues, tableSubmoduleId]);

  useEffect(() => {
    if (dataTable2 === undefined) return;
    if (!isTotalCalculated2.current) {
      const table2Values = { ...dataTable2?.values };
      const table2UpdatedValues = calculateTable2Totals(table2Values as Record<string, number | null>);

      dispatch(
        updateDataTable({
          dataTable: {
            tabKey,
            table: TypeAndFunctionTables.MarketingCostsTable2DigitalChannelsMarketingSpend,
            values: table2UpdatedValues[0],
          },
          columns: ['A'],
          rowCount: 6,
        }),
      );

      const table1UpdatedValues = calculateTable1Totals({ ...dataTable1?.values, ...table2UpdatedValues[1] });

      dispatch(
        updateDataTable({
          dataTable: {
            tabKey,
            table: TypeAndFunctionTables.MarketingCostsTable1TotalMarketing,
            values: table1UpdatedValues,
          },
          columns: ['A'],
          rowCount: 4,
        }),
      );
      isTotalCalculated2.current = true;
    }
    void getCommentThreads({
      dataTableId: dataTable2.id,
      fetchDataTableCommentThreads,
      dispatch,
    });

    void getIssues({
      dataTableId: dataTable2.id,
      submoduleId: tableSubmoduleId,
      dispatch,
      fetchDataTableIssues,
    });
  }, [dataTable2, dispatch, fetchDataTableCommentThreads, fetchDataTableIssues, tableSubmoduleId]);

  useEffect(() => {
    if (dataTable3 === undefined) return;
    if (!isTotalCalculated3.current) {
      const values = { ...dataTable3?.values };
      const updatedValues = calculateTable3Totals(values as Record<string, number | null>);

      dispatch(
        updateDataTable({
          dataTable: {
            tabKey,
            table: TypeAndFunctionTables.MarketingCostsTable3SalesAndPromotion,
            values: updatedValues,
          },
          columns: ['A', 'B'],
          rowCount: 4,
        }),
      );
      isTotalCalculated3.current = true;
    }
    void getCommentThreads({
      dataTableId: dataTable3.id,
      fetchDataTableCommentThreads,
      dispatch,
    });

    void getIssues({
      dataTableId: dataTable3.id,
      submoduleId: tableSubmoduleId,
      dispatch,
      fetchDataTableIssues,
    });
  }, [dataTable3, dispatch, fetchDataTableCommentThreads, fetchDataTableIssues, tableSubmoduleId]);

  const calculateTable1Totals = (values: Record<string, number | null>) => {
    const a1 = formatNumber(sumValues(['A2', 'A3', 'A4'], values));

    return { ...values, A1: a1 };
  };

  const calculateTable2Totals = (values: Record<string, number | null>) => {
    const a1 = formatNumber(sumValues(['A2', 'A3', 'A4', 'A5', 'A6'], values));

    return [{ ...values, A1: a1 }, { A3: a1 }] as Array<Record<string, number | null>>;
  };

  const calculateTable3Totals = (values: Record<string, number | null>) => {
    const a1 = formatNumber(sumValues(['A2', 'A3', 'A4'], values));
    const b1 = formatNumber(sumValues(['B2', 'B3', 'B4'], values));

    return { ...values, A1: a1, B1: b1 };
  };

  const handleClick = (cellId: string, column: string, row: string, dataTableId: number | undefined) => {
    if (timer === null) {
      const newTimer = setTimeout(() => {
        setTimer(null);
      }, 250);
      setTimer(newTimer);
    } else {
      clearTimeout(timer);
      setTimer(null);
      handleOpenComments(dispatch, dataTableId, { cellId, column, row });
    }
  };

  const handleValueChangeTable1 = (cellId: string, newValue: number | null) => {
    const values = { ...dataTable1?.values, [cellId]: newValue };
    const updatedValues = calculateTable1Totals(values as Record<string, number | null>);

    dispatch(
      updateDataTable({
        dataTable: {
          tabKey,
          table: TypeAndFunctionTables.MarketingCostsTable1TotalMarketing,
          values: updatedValues,
        },
        columns: ['A'],
        rowCount: 4,
      }),
    );
  };

  const handleValueChangeTable2 = (cellId: string, newValue: number | null) => {
    const table2Values = { ...dataTable2?.values, [cellId]: newValue };
    const table2UpdatedValues = calculateTable2Totals(table2Values as Record<string, number | null>);

    dispatch(
      updateDataTable({
        dataTable: {
          tabKey,
          table: TypeAndFunctionTables.MarketingCostsTable2DigitalChannelsMarketingSpend,
          values: table2UpdatedValues[0],
        },
        columns: ['A'],
        rowCount: 6,
      }),
    );

    const table1UpdatedValues = calculateTable1Totals({ ...dataTable1?.values, ...table2UpdatedValues[1] });

    dispatch(
      updateDataTable({
        dataTable: {
          tabKey,
          table: TypeAndFunctionTables.MarketingCostsTable1TotalMarketing,
          values: table1UpdatedValues,
        },
        columns: ['A'],
        rowCount: 4,
      }),
    );
  };

  const handleValueChangeTable3 = (cellId: string, newValue: number | null) => {
    const values = { ...dataTable3?.values, [cellId]: newValue };
    const updatedValues = calculateTable3Totals(values as Record<string, number | null>);

    dispatch(
      updateDataTable({
        dataTable: {
          tabKey,
          table: TypeAndFunctionTables.MarketingCostsTable3SalesAndPromotion,
          values: updatedValues,
        },
        columns: ['A', 'B'],
        rowCount: 4,
      }),
    );
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
      <Box>
        <TableComponent
          categories={table1Categories.current}
          columns={table1Columns.current}
          values={dataTable1?.values ?? {}}
          onValueChange={handleValueChangeTable1}
          onClickCell={(cellId, column, row) => {
            handleClick(cellId, column, row, dataTable1?.id);
          }}
          onRightClickCell={(cellId, column, row) => {
            handleOpenComments(dispatch, dataTable1?.id, { cellId, column, row });
          }}
          banner={true}
          bannerValue={`${workspace?.beginning_of_period} - ${workspace?.end_of_period}`}
          cellsWithCommentsSet={commentThreadsToCellSet(dataTable1?.commentThreads)}
          issues={dataTable1?.issues ?? []}
        />
      </Box>
      <Box>
        <TableComponent
          categories={table2Categories.current}
          columns={table2Columns.current}
          values={dataTable2?.values ?? {}}
          onValueChange={handleValueChangeTable2}
          onClickCell={(cellId, column, row) => {
            handleClick(cellId, column, row, dataTable2?.id);
          }}
          onRightClickCell={(cellId, column, row) => {
            handleOpenComments(dispatch, dataTable2?.id, { cellId, column, row });
          }}
          banner={true}
          bannerValue={`${workspace?.beginning_of_period} - ${workspace?.end_of_period}`}
          cellsWithCommentsSet={commentThreadsToCellSet(dataTable1?.commentThreads)}
          issues={dataTable2?.issues ?? []}
        />
      </Box>
      <Box>
        <TableComponent
          categories={table3Categories.current}
          columns={table3Columns.current}
          values={dataTable3?.values ?? {}}
          onValueChange={handleValueChangeTable3}
          onClickCell={(cellId, column, row) => {
            handleClick(cellId, column, row, dataTable3?.id);
          }}
          onRightClickCell={(cellId, column, row) => {
            handleOpenComments(dispatch, dataTable3?.id, { cellId, column, row });
          }}
          banner={true}
          bannerValue={`${workspace?.beginning_of_period} - ${workspace?.end_of_period}`}
          cellsWithCommentsSet={commentThreadsToCellSet(dataTable1?.commentThreads)}
          issues={dataTable3?.issues ?? []}
        />
      </Box>
    </Box>
  );
};

export default MarketingCosts;
