import { Box } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useWorkspaceDataContext } from '../../../../../../../../../context/WorkspaceDataContext';
import { useAppSelector } from '../../../../../../../../../utils/hooks/useAppSelector';
import {
  useGetDataTableQuery,
  useLazyGetAllCommentThreadsByDataTableQuery,
  useLazyGetIssuesQuery,
} from '../../../../../../../../../utils/redux/api';
import {
  addDataTable,
  selectDataTableByTabKeyAndTable,
  updateDataTable,
} from '../../../../../../../../../utils/redux/dataTablesSlice';
import { OnlineAndMobileCustomersTables } from '../../../../../../../../../utils/types/DataTablesEnum';
import TableComponent, {
  type Category,
  type Column,
} from '../../../../../../../../DataTables/TableComponent/TableComponent';
import { commentThreadsToCellSet } from '../../../../../../../../DataTables/utils/commentThreadsToCellSet';
import { getCommentThreads, getIssues } from '../../../../../../../../DataTables/utils/getCommentThreads';
import { formatNumber } from '../../../../../../../../DataTables/utils/numberFormat';
import { sumValues } from '../../../../../../../../DataTables/utils/sumValues';
import { handleOpenComments } from '../../utils/handleOpenComments';

const TransactionsInDigital: React.FC = () => {
  const tabKey = useAppSelector((state) => state.dataTables.activeTabKey);
  const { submoduleId: stringSubmoduleId } = useParams();
  const tableSubmoduleId = Number(stringSubmoduleId);
  const dispatch = useDispatch();
  const dataTable = useAppSelector((state) =>
    selectDataTableByTabKeyAndTable(state, tabKey, OnlineAndMobileCustomersTables.TransactionsInDigital),
  );

  const isTotalCalculated = useRef(false);

  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  const { activeWorkspace: workspace } = useWorkspaceDataContext();
  const fetchFlag = useAppSelector((state) => state.fetchFlags.refetch);
  const {
    data: dataTableResponse,
    isLoading: isLoadingDataTable,
    refetch,
  } = useGetDataTableQuery({
    submodule_id: tableSubmoduleId,
    table: OnlineAndMobileCustomersTables.TransactionsInDigital,
  });
  const [fetchDataTableCommentThreads] = useLazyGetAllCommentThreadsByDataTableQuery();
  const [fetchDataTableIssues] = useLazyGetIssuesQuery();

  const categories = useRef<Category[]>([
    { label: 'Number of customer initiated transactions', editable: true, indentLevel: 1 },
    { label: 'of which inter-account transfers', editable: true, indentLevel: 2 },
    { label: 'of which P2P payments', editable: true, indentLevel: 2 },
    { label: 'of which wire or global transfers', editable: true, indentLevel: 2 },
    { label: 'of which bill payments', editable: true, indentLevel: 2 },
    { label: 'of which mobile cheque deposits', editable: true, indentLevel: 2 },
  ]);
  const columns = useRef<Column[]>([
    { key: 'A', label: 'Mobile app (#)', editable: true },
    { key: 'B', label: 'Online (#)', editable: true },
    { key: 'C', label: 'Digital (#)', editable: false },
  ]);

  useEffect(() => {
    if (!isLoadingDataTable) {
      dispatch(
        addDataTable({
          dataTable: {
            id: dataTableResponse?.id ?? 0,
            commentCount: dataTableResponse?.comments_amount ?? 0,
            submodule_id: tableSubmoduleId,
            tabKey,
            table: OnlineAndMobileCustomersTables.TransactionsInDigital,
            data: dataTableResponse?.data ?? [],
            columns: dataTableResponse?.columns ?? [],
            rows: dataTableResponse?.rows ?? [],
          },
          columns: ['A', 'B', 'C'],
          rowCount: 6,
          totalColumns: ['C1', 'C2', 'C3', 'C4', 'C5'],
        }),
      );
      // to update the auto calculated values
      isTotalCalculated.current = false;
      dataTableResponse?.rows.forEach((rowName, index) => {
        categories.current[index].rowName = rowName;
      });

      dataTableResponse?.columns?.forEach((columnName, index) => {
        columns.current[index].columnName = columnName;
      });
    }
  }, [dispatch, dataTableResponse, isLoadingDataTable, tabKey, tableSubmoduleId]);

  useEffect(() => {
    refetch().catch((err) => {
      console.error(err);
    });
  }, [fetchFlag, refetch]);

  useEffect(() => {
    if (dataTable === undefined) return;
    if (!isTotalCalculated.current) {
      const values = { ...dataTable?.values };
      const updatedValues = calculateTotals(values as Record<string, number | null>);

      dispatch(
        updateDataTable({
          dataTable: {
            tabKey,
            table: OnlineAndMobileCustomersTables.TransactionsInDigital,
            values: updatedValues,
          },
          columns: ['A', 'B', 'C'],
          rowCount: 6,
        }),
      );
      isTotalCalculated.current = true;
    }
    void getCommentThreads({
      dataTableId: dataTable.id,
      fetchDataTableCommentThreads,
      dispatch,
    });

    void getIssues({
      dataTableId: dataTable.id,
      submoduleId: tableSubmoduleId,
      dispatch,
      fetchDataTableIssues,
    });
  }, [dataTable, dispatch, fetchDataTableCommentThreads, fetchDataTableIssues, tableSubmoduleId]);

  const calculateTotals = (values: Record<string, number | null>) => {
    const c1 = formatNumber(sumValues(['A1', 'B1'], values));
    const c2 = formatNumber(sumValues(['A2', 'B2'], values));
    const c3 = formatNumber(sumValues(['A3', 'B3'], values));
    const c4 = formatNumber(sumValues(['A4', 'B4'], values));
    const c5 = formatNumber(sumValues(['A5', 'B5'], values));

    return {
      ...values,
      C1: c1,
      C2: c2,
      C3: c3,
      C4: c4,
      C5: c5,
    };
  };

  const isCellDisabled = (category: Category, column: Column) => {
    if (category.label === 'of which mobile cheque deposits' && column.label === 'Online (#)') {
      return true;
    }
    if (category.label === 'of which mobile cheque deposits' && column.label === 'Digital (#)') {
      return true;
    }
    return false;
  };

  const handleClick = (cellId: string, column: string, row: string) => {
    if (timer === null) {
      const newTimer = setTimeout(() => {
        setTimer(null);
      }, 250);
      setTimer(newTimer);
    } else {
      clearTimeout(timer);
      setTimer(null);
      handleOpenComments(dispatch, dataTable?.id, { cellId, column, row });
    }
  };

  const handleValueChange = (cellId: string, newValue: number | null) => {
    const values = { ...dataTable?.values, [cellId]: newValue };
    const updatedValues = calculateTotals(values as Record<string, number | null>);

    dispatch(
      updateDataTable({
        dataTable: {
          tabKey,
          table: OnlineAndMobileCustomersTables.TransactionsInDigital,
          values: updatedValues,
        },
        columns: ['A', 'B', 'C'],
        rowCount: 6,
      }),
    );
  };

  return (
    <Box>
      <Box>
        <TableComponent
          categories={categories.current}
          columns={columns.current}
          values={dataTable?.values ?? {}}
          onValueChange={handleValueChange}
          onClickCell={handleClick}
          onRightClickCell={(cellId, column, row) => {
            handleOpenComments(dispatch, dataTable?.id, { cellId, column, row });
          }}
          banner={true}
          bannerValue={`${workspace?.beginning_of_period} - ${workspace?.end_of_period}`}
          isCellDisabled={isCellDisabled}
          cellsWithCommentsSet={commentThreadsToCellSet(dataTable?.commentThreads)}
          issues={dataTable?.issues ?? []}
        />
      </Box>
    </Box>
  );
};

export default TransactionsInDigital;
