import React, { Fragment, useEffect, useState } from 'react';
import classes from './ChatBox.module.css';
import { CircularProgress } from '@mui/material';
import ReactECharts from 'echarts-for-react';
import zIndex from '@mui/material/styles/zIndex';

interface IBarChartProps {
  categories: string[] | undefined;
  data: number[] | undefined;
  barColor?: string;
  chartTitle?: string | undefined;
  chartType?: string;
  chartSubTitle?: string;
}

const ChatBarChart: React.FC<IBarChartProps> = ({
  categories,
  data,
  chartTitle,
  chartType = 'srv',
  chartSubTitle,
}) => {
  const [chartoptions, setChartOptions] = useState<any>({});

  useEffect(() => {
    if (categories !== undefined && categories.length > 0 && data !== undefined && data.length > 0) {
      setChartOptions({
        legend: {
          show: true,
        },
        tooltip: {
          trigger: 'axis',
        },
        color: ['#00796b'],
        xAxis: {
          type: 'category',
          data: categories,
          axisLabel: {
            show: true,
            interval: 0, // Show all labels
            rotate: 0,
            formatter: (value: string) => {
              return value.split(' ').join('\n'); // Split text into multiple lines
            },
          },
        },
        yAxis: {
          type: 'value',
        },
        series: [
          {
            data: data,
            type: 'bar',
            smooth: true,
            label: {
              show: true,
              position: 'top',
              color: '#000',
              fontWeight: 'bold',
              formatter: (params: { name: string; value: number }) => {
                return chartType === 'bm' ? `${Number(params?.value.toFixed(2))}` : ''; // Display "name - value"
              },
              rich: {
                label: {
                  lineHeight: 20, // Adjust line spacing
                  fontSize: 12, // Font size for labels
                },
              },
              verticalAlign: 'middle',
            },
            itemStyle: {
              color: (params: { dataIndex: number }) => {
                if (params.dataIndex === data.length - 1 && chartType === 'bm') {
                  return '#003D35'; // Different color for the last bar
                }
                return '#00796b'; // Default color for other bars
              },
              barBorderRadius: [5, 5, 0, 0], // Rounded corners for the top of the bar
              shadowColor: '#999', // Shadow color
              shadowBlur: 7, // Blur radius for the shadow
              shadowOffsetX: 5, // Horizontal shadow offset
              shadowOffsetY: 1,
            },
          },
        ],
        title: {
          text: chartType === 'bm' ? chartTitle : '',
          subtext: chartType === 'bm' ? `( In ${chartSubTitle} )` : '', // Corrected property
          left: 'center',
          top: 'top', // Subtitle will appear beneath the main title
          textStyle: {
            fontSize: 14,
            fontWeight: 'bold',
          },
          subtextStyle: {
            fontSize: 12,
            color: '#888', // Subtitle color
            fontWeight: 'bold',
          },
        },
        toolbox: {
          show: true,
          feature: {
            dataView: {
              readOnly: false,
              optionToContent: (opt: echarts.EChartsOption) => {
                const seriesData = opt.series as echarts.SeriesOption[];
                const categories = (opt.xAxis as any[])[0].data as string[];

                let table = `
                  <style>
                    table.custom-data-view {
                      width: 100%;
                      border-collapse: collapse;
                      font-family: Arial, sans-serif;
                      font-size: 14px;
                      text-align: left;
                    }
                    table.custom-data-view th, table.custom-data-view td {
                      border: 1px solid #ddd;
                      padding: 8px;
                    }
                    table.custom-data-view th {
                      background-color: #f4f4f4;
                      color: #333;
                    }
                    table.custom-data-view tbody tr:hover {
                      background-color: #f9f9f9;
                    }
                  </style>
                  <table class="custom-data-view">
                    <thead>
                      <tr>
                        <th>Peer</th>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                `;
                categories.forEach((category, index) => {
                  table += `<tr><td>${category}</td>`;
                  seriesData.forEach((series) => {
                    if (series.data !== undefined && Array.isArray(series.data)) {
                      table += `<td>${(series.data as number[])[index]}</td>`;
                    } else {
                      table += `<td>N/A</td>`;
                    }
                  });
                  table += `</tr>`;
                });
                table += `
                    </tbody>
                  </table>
                `;
                return table;
              },
              title: 'Data View',
            },
            magicType: { type: ['bar', 'line'] },
            saveAsImage: {
              show: true,
              title: 'Save as Image',
              name: chartTitle,
              type: 'png',
              pixelRatio: 2,
            },
          },
        },
      });
    }
  }, [categories, data, chartTitle, chartSubTitle]);

  return (
    <Fragment>
      <ReactECharts
        option={chartoptions}
        style={{ height: '400px', width: chartType === 'srv' ? '90%' : '47%', margin: '0 auto' }}
        className={classes.chart_wrapper}
      />
    </Fragment>
  );
};

export default ChatBarChart;
