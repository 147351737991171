import { Box } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useWorkspaceDataContext } from '../../../../../../../../../context/WorkspaceDataContext';
import { useAppSelector } from '../../../../../../../../../utils/hooks/useAppSelector';
import {
  useGetDataTableQuery,
  useLazyGetAllCommentThreadsByDataTableQuery,
  useLazyGetIssuesQuery,
} from '../../../../../../../../../utils/redux/api';
import {
  addDataTable,
  selectDataTableByTabKeyAndTable,
  updateDataTable,
} from '../../../../../../../../../utils/redux/dataTablesSlice';
import { CustomersTables } from '../../../../../../../../../utils/types/DataTablesEnum';
import TableComponent, {
  type Category,
  type Column,
} from '../../../../../../../../DataTables/TableComponent/TableComponent';
import { commentThreadsToCellSet } from '../../../../../../../../DataTables/utils/commentThreadsToCellSet';
import { getCommentThreads, getIssues } from '../../../../../../../../DataTables/utils/getCommentThreads';
import { formatNumber } from '../../../../../../../../DataTables/utils/numberFormat';
import { sumValues } from '../../../../../../../../DataTables/utils/sumValues';
import { handleOpenComments } from '../../utils/handleOpenComments';

const TotalCustomersOverviewAndSegments: React.FC = () => {
  const tabKey = useAppSelector((state) => state.dataTables.activeTabKey);
  const { submoduleId: stringSubmoduleId } = useParams();
  const tableSubmoduleId = Number(stringSubmoduleId);
  const dispatch = useDispatch();
  const dataTable1 = useAppSelector((state) =>
    selectDataTableByTabKeyAndTable(state, tabKey, CustomersTables.TotalCustomersOverviewAndSegmentsTable1),
  );
  const dataTable2 = useAppSelector((state) =>
    selectDataTableByTabKeyAndTable(state, tabKey, CustomersTables.TotalCustomersOverviewAndSegmentsTable2),
  );
  const dataTable3 = useAppSelector((state) =>
    selectDataTableByTabKeyAndTable(state, tabKey, CustomersTables.TotalCustomersOverviewAndSegmentsTable3),
  );

  const isTotalCalculated = useRef(false);

  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  const { activeWorkspace: workspace } = useWorkspaceDataContext();
  const fetchFlag = useAppSelector((state) => state.fetchFlags.refetch);
  const {
    data: dataTableResponse1,
    isLoading: isLoadingDataTable1,
    refetch: refetchDataTable1,
  } = useGetDataTableQuery({
    submodule_id: tableSubmoduleId,
    table: CustomersTables.TotalCustomersOverviewAndSegmentsTable1,
  });
  const {
    data: dataTableResponse2,
    isLoading: isLoadingDataTable2,
    refetch: refetchDataTable2,
  } = useGetDataTableQuery({
    submodule_id: tableSubmoduleId,
    table: CustomersTables.TotalCustomersOverviewAndSegmentsTable2,
  });
  const {
    data: dataTableResponse3,
    isLoading: isLoadingDataTable3,
    refetch: refetchDataTable3,
  } = useGetDataTableQuery({
    submodule_id: tableSubmoduleId,
    table: CustomersTables.TotalCustomersOverviewAndSegmentsTable3,
  });
  const [fetchDataTableCommentThreads] = useLazyGetAllCommentThreadsByDataTableQuery();
  const [fetchDataTableIssues] = useLazyGetIssuesQuery();

  const table1Categories = useRef<Category[]>([
    { label: 'Retail customers', editable: true, indentLevel: 1 },
    { label: 'Active customers', editable: true, indentLevel: 1 },
    { label: 'Primary customers', editable: true, indentLevel: 1 },
    { label: 'Checking account customers', editable: true, indentLevel: 1 },
  ]);
  const table1Columns = useRef<Column[]>([
    { key: 'A', label: 'End of study period - 2 years', editable: true },
    { key: 'B', label: 'End of study period - 1 year', editable: true },
    { key: 'C', label: 'End of study period', editable: true },
  ]);

  const table2Categories = useRef<Category[]>([
    { label: 'Deposit-only customers', editable: true, indentLevel: 1 },
    { label: 'Credit card only customers', editable: true, indentLevel: 1 },
    { label: 'Mono-product customers', editable: true, indentLevel: 1 },
    { label: 'Indirect auto customers', editable: true, indentLevel: 1 },
  ]);
  const table2Columns = useRef<Column[]>([
    { key: 'A', label: '', editable: true },
    { key: 'B', label: 'End of study period - 1 year', editable: true },
    { key: 'C', label: 'End of study period', editable: true },
  ]);

  const table3Categories = useRef<Category[]>([
    { label: 'Retail customers', editable: false, indentLevel: 1 },
    { label: 'of which mass market ', editable: true, indentLevel: 2 },
    { label: 'of which affluent ', editable: true, indentLevel: 2 },
  ]);
  const table3Columns = useRef<Column[]>([{ key: 'A', label: 'End of study period', editable: true }]);

  useEffect(() => {
    if (!isLoadingDataTable1) {
      dispatch(
        addDataTable({
          dataTable: {
            id: dataTableResponse1?.id ?? 0,
            commentCount: dataTableResponse1?.comments_amount ?? 0,
            submodule_id: tableSubmoduleId,
            tabKey,
            table: CustomersTables.TotalCustomersOverviewAndSegmentsTable1,
            data: dataTableResponse1?.data ?? [],
            columns: dataTableResponse1?.columns ?? [],
            rows: dataTableResponse1?.rows ?? [],
          },
          columns: ['A', 'B', 'C'],
          rowCount: 4,
          totalColumns: ['C1'],
        }),
      );
    }
    dataTableResponse1?.rows.forEach((rowName, index) => {
      table1Categories.current[index].rowName = rowName;
    });

    dataTableResponse1?.columns?.forEach((columnName, index) => {
      table1Columns.current[index].columnName = columnName;
    });

    if (!isLoadingDataTable2) {
      dispatch(
        addDataTable({
          dataTable: {
            id: dataTableResponse2?.id ?? 0,
            commentCount: dataTableResponse2?.comments_amount ?? 0,
            submodule_id: tableSubmoduleId,
            tabKey,
            table: CustomersTables.TotalCustomersOverviewAndSegmentsTable2,
            data: dataTableResponse2?.data ?? [],
            columns: dataTableResponse2?.columns ?? [],
            rows: dataTableResponse2?.rows ?? [],
          },
          columns: ['A', 'B', 'C'],
          rowCount: 4,
        }),
      );

      dataTableResponse2?.rows.forEach((rowName, index) => {
        table2Categories.current[index].rowName = rowName;
      });

      dataTableResponse2?.columns?.forEach((columnName, index) => {
        table2Columns.current[index].columnName = columnName;
      });
    }

    if (!isLoadingDataTable3) {
      dispatch(
        addDataTable({
          dataTable: {
            id: dataTableResponse3?.id ?? 0,
            commentCount: dataTableResponse3?.comments_amount ?? 0,
            submodule_id: tableSubmoduleId,
            tabKey,
            table: CustomersTables.TotalCustomersOverviewAndSegmentsTable3,
            data: dataTableResponse3?.data ?? [],
            columns: dataTableResponse3?.columns ?? [],
            rows: dataTableResponse3?.rows ?? [],
          },
          columns: ['A'],
          rowCount: 3,
          totalColumns: ['A1'],
        }),
      );
      // to update the auto calculated values
      isTotalCalculated.current = false;
      dataTableResponse3?.rows.forEach((rowName, index) => {
        table3Categories.current[index].rowName = rowName;
      });

      dataTableResponse3?.columns?.forEach((columnName, index) => {
        table3Columns.current[index].columnName = columnName;
      });
    }
  }, [
    dispatch,
    dataTableResponse1,
    isLoadingDataTable1,
    dataTableResponse2,
    isLoadingDataTable2,
    dataTableResponse3,
    isLoadingDataTable3,
    tableSubmoduleId,
    tabKey,
  ]);

  useEffect(() => {
    const refetchAll = async () => {
      try {
        await Promise.all([refetchDataTable1(), refetchDataTable2(), refetchDataTable3()]);
      } catch (error) {
        console.log('Error in fetching data');
      }
    };

    refetchAll().catch((err) => {
      console.log(err);
    });
  }, [fetchFlag, refetchDataTable1, refetchDataTable2, refetchDataTable3]);

  useEffect(() => {
    if (dataTable1 === undefined) return;
    void getCommentThreads({
      dataTableId: dataTable1.id,
      fetchDataTableCommentThreads,
      dispatch,
    });

    void getIssues({
      dataTableId: dataTable1.id,
      submoduleId: tableSubmoduleId,
      dispatch,
      fetchDataTableIssues,
    });
  }, [dataTable1, dispatch, fetchDataTableCommentThreads, fetchDataTableIssues, tableSubmoduleId]);

  useEffect(() => {
    if (dataTable2 === undefined) return;
    void getCommentThreads({
      dataTableId: dataTable2.id,
      fetchDataTableCommentThreads,
      dispatch,
    });

    void getIssues({
      dataTableId: dataTable2.id,
      submoduleId: tableSubmoduleId,
      dispatch,
      fetchDataTableIssues,
    });
  }, [dataTable2, dispatch, fetchDataTableCommentThreads, fetchDataTableIssues, tableSubmoduleId]);

  useEffect(() => {
    if (dataTable3 === undefined) return;
    if (!isTotalCalculated.current) {
      const values = { ...dataTable3?.values };
      const updatedValues = calculateTotals(values as Record<string, number | null>);

      dispatch(
        updateDataTable({
          dataTable: {
            tabKey,
            table: CustomersTables.TotalCustomersOverviewAndSegmentsTable3,
            values: updatedValues[0],
          },
          columns: ['A'],
          rowCount: 3,
        }),
      );

      dispatch(
        updateDataTable({
          dataTable: {
            tabKey,
            table: CustomersTables.TotalCustomersOverviewAndSegmentsTable1,
            values: { ...dataTable1?.values, ...updatedValues[1] },
          },
          columns: ['A', 'B', 'C'],
          rowCount: 4,
        }),
      );

      isTotalCalculated.current = true;
    }
    void getCommentThreads({
      dataTableId: dataTable3.id,
      fetchDataTableCommentThreads,
      dispatch,
    });

    void getIssues({
      dataTableId: dataTable3.id,
      submoduleId: tableSubmoduleId,
      dispatch,
      fetchDataTableIssues,
    });
  }, [dataTable3, dispatch, fetchDataTableCommentThreads, fetchDataTableIssues, tableSubmoduleId]);

  const calculateTotals = (values: Record<string, number | null>) => {
    const table3a1 = formatNumber(sumValues(['A2', 'A3'], values));

    return [{ ...values, A1: table3a1 }, { C1: table3a1 }] as Array<Record<string, number | null>>;
  };

  const isCellDisabled = (category: Category, column: Column) => {
    if (category.label === 'Deposit-only customers' && column.label === '') {
      return true;
    }
    if (category.label === 'Credit card only customers' && column.label === '') {
      return true;
    }
    if (category.label === 'Mono-product customers' && column.label === '') {
      return true;
    }
    if (category.label === 'Indirect auto customers' && column.label === '') {
      return true;
    }
    return false;
  };

  const handleClick = (cellId: string, column: string, row: string, dataTableId: number | undefined) => {
    if (timer === null) {
      const newTimer = setTimeout(() => {
        setTimer(null);
      }, 250);
      setTimer(newTimer);
    } else {
      clearTimeout(timer);
      setTimer(null);
      handleOpenComments(dispatch, dataTableId, { cellId, column, row });
    }
  };

  const handleValueChangeTable1 = (cellId: string, newValue: number | null) => {
    dispatch(
      updateDataTable({
        dataTable: {
          tabKey,
          table: CustomersTables.TotalCustomersOverviewAndSegmentsTable1,
          values: { ...dataTable1?.values, [cellId]: newValue },
        },
        columns: ['A', 'B', 'C'],
        rowCount: 4,
      }),
    );
  };

  const handleValueChangeTable2 = (cellId: string, newValue: number | null) => {
    dispatch(
      updateDataTable({
        dataTable: {
          tabKey,
          table: CustomersTables.TotalCustomersOverviewAndSegmentsTable2,
          values: { ...dataTable2?.values, [cellId]: newValue },
        },
        columns: ['A', 'B', 'C'],
        rowCount: 4,
      }),
    );
  };

  const handleValueChangeTable3 = (cellId: string, newValue: number | null) => {
    const values = { ...dataTable3?.values, [cellId]: newValue };
    const updatedValues = calculateTotals(values as Record<string, number | null>);

    dispatch(
      updateDataTable({
        dataTable: {
          tabKey,
          table: CustomersTables.TotalCustomersOverviewAndSegmentsTable3,
          values: updatedValues[0],
        },
        columns: ['A'],
        rowCount: 3,
      }),
    );

    dispatch(
      updateDataTable({
        dataTable: {
          tabKey,
          table: CustomersTables.TotalCustomersOverviewAndSegmentsTable1,
          values: { ...dataTable1?.values, ...updatedValues[1] },
        },
        columns: ['A', 'B', 'C'],
        rowCount: 4,
      }),
    );
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
      <Box>
        <TableComponent
          categories={table1Categories.current}
          columns={table1Columns.current}
          values={dataTable1?.values ?? {}}
          onValueChange={handleValueChangeTable1}
          onClickCell={(cellId, column, row) => {
            handleClick(cellId, column, row, dataTable1?.id);
          }}
          onRightClickCell={(cellId, column, row) => {
            handleOpenComments(dispatch, dataTable1?.id, { cellId, column, row });
          }}
          banner={true}
          bannerValue={`${workspace!.end_of_period}`}
          cellsWithCommentsSet={commentThreadsToCellSet(dataTable1?.commentThreads)}
          issues={dataTable1?.issues ?? []}
        />
      </Box>
      <Box>
        <TableComponent
          categories={table2Categories.current}
          columns={table2Columns.current}
          values={dataTable2?.values ?? {}}
          onValueChange={handleValueChangeTable2}
          onClickCell={(cellId, column, row) => {
            handleClick(cellId, column, row, dataTable2?.id);
          }}
          onRightClickCell={(cellId, column, row) => {
            handleOpenComments(dispatch, dataTable2?.id, { cellId, column, row });
          }}
          banner={true}
          // bannerValue={` ${'\u00A0'.repeat(58)} ${workspace!.beginning_of_period} ${'\u00A0'.repeat(37)} ${workspace!.end_of_period}`}
          bannerValue={`${workspace!.end_of_period}`}
          isCellDisabled={isCellDisabled}
          cellsWithCommentsSet={commentThreadsToCellSet(dataTable1?.commentThreads)}
          issues={dataTable2?.issues ?? []}
        />
      </Box>
      <Box>
        <TableComponent
          categories={table3Categories.current}
          columns={table3Columns.current}
          values={dataTable3?.values ?? {}}
          onValueChange={handleValueChangeTable3}
          onClickCell={(cellId, column, row) => {
            handleClick(cellId, column, row, dataTable3?.id);
          }}
          onRightClickCell={(cellId, column, row) => {
            handleOpenComments(dispatch, dataTable3?.id, { cellId, column, row });
          }}
          banner={true}
          bannerValue={`${workspace!.end_of_period}`}
          cellsWithCommentsSet={commentThreadsToCellSet(dataTable1?.commentThreads)}
          issues={dataTable3?.issues ?? []}
        />
      </Box>
    </Box>
  );
};

export default TotalCustomersOverviewAndSegments;
