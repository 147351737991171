import React, { Fragment, useEffect, useState } from 'react';
import classes from './ChatBox.module.css';
import Person2Icon from '@mui/icons-material/Person2';
import chatBot from '../../../../assets/svg/chatBot.svg';
import { Box, Chip, CircularProgress, IconButton, Tooltip } from '@mui/material';
import ChatBarChart from './ChatBarChart'; // Import the ChatBarChart component
import DOMPurify from 'dompurify';
import RenderHTML from './RenderHtml';
import BarChartIcon from '@mui/icons-material/BarChart'; // Import BarChartIcon
import type { IBMChartData } from '../../../../utils/types/ChatBot';
import MessageIcon from '@mui/icons-material/Message';
import { set } from 'lodash';

interface ChatMessageProps {
  id: string;
  message: string | null; // Explicitly typed message to avoid 'any'
  isChatLoading?: boolean;
  isLastIndex: boolean;
  isChart?: boolean; // Renamed to camelCase
  chart?: { categories: string[] | undefined; values: number[] | undefined; chartMessage: string }; // Chart data if isChart is true
  kpiList?: string[] | undefined; // Optional list of KPIs
  mode?: string | undefined;
  bmChartData?: IBMChartData;
}

const ChatMessage: React.FC<ChatMessageProps> = ({
  id,
  message,
  isChatLoading = false, // Default value for isChatLoading
  isLastIndex,
  isChart, // camelCase
  chart,
  kpiList,
  mode,
  bmChartData,
}) => {
  const [selectedOptions, setSelectedOptions] = useState<any>(new Set());
  const [bmChartMode, setBmChartMode] = useState<boolean>(false);

  // Add kpiList as a dependency
  const sanitizedHTML = DOMPurify.sanitize(message ?? ''); // Sanitize the message to prevent XSS attacks

  const kpiSelectHandler = (kpi: string) => {
    if (selectedOptions.has(kpi) === true) {
      selectedOptions.delete(kpi);
    } else {
      selectedOptions.add(kpi);
    }
  };

  return (
    <div className={id === 'user' ? classes.user_chat : classes.genie_chat}>
      {id === 'genie' ? (
        <>
          <div className={classes.chat_icon_wrapper}>
            <img src={chatBot} alt="Genie Icon" className={classes.bot_icon} />
          </div>
          {isChatLoading ? (
            <>
              <span className={classes.message_text}>
                <RenderHTML htmlString={sanitizedHTML} />
                {isLastIndex && <CircularProgress sx={{ color: '#fff' }} size="1rem" />}
              </span>
            </>
          ) : (
            // If the response contains a chart, render the chart; otherwise, render the text
            <>
              {/* {isChart === true && chart != null ? ( // Explicitly checking chart for nullish values
                <div className={classes.chart_chat_wrapper}>
                  <span className={classes.message_text}>{chart.chartMessage}</span>
                  <ChatBarChart categories={chart.categories} data={chart.values} />
                </div>
              ) : (
                <span className={classes.message_text}>
                  {bmChartData !== undefined && (
                    <div className={classes.bar_chart_icon}>
                      <BarChartIcon fontSize="small" sx={{ color: '#fff' }} />
                    </div>
                  )}
                  <RenderHTML htmlString={sanitizedHTML} />
                </span>
              )} */}

              {isChart === true && chart != null ? (
                <div className={classes.chart_chat_wrapper}>
                  <span className={classes.message_text}>{chart.chartMessage}</span>
                  <ChatBarChart categories={chart.categories} data={chart.values} />
                </div>
              ) : (
                <span className={!bmChartMode ? classes.message_text : classes.chat_chart_wrapper}>
                  {bmChartData !== undefined && !bmChartMode && (
                    <div className={classes.bar_chart_icon}>
                      <Tooltip title="Chart View" placement="top">
                        <IconButton
                          onClick={() => {
                            setBmChartMode(true); // Set the mode to true for chart display
                            console.log(bmChartData);
                          }}
                        >
                          <BarChartIcon fontSize="small" sx={{ color: '#fff' }} />
                        </IconButton>
                      </Tooltip>
                    </div>
                  )}
                  {!bmChartMode ? (
                    <RenderHTML htmlString={sanitizedHTML} />
                  ) : (
                    // Render the chart when bmChartMode is true
                    <>
                      <Box width="100%" display="flex" justifyContent="flex-end" alignItems="center" gap={1}>
                        <Tooltip title="Back to Chat" placement="top">
                          <IconButton
                            onClick={() => {
                              setBmChartMode(false); // Set the mode to true for chart display
                            }}
                          >
                            <MessageIcon fontSize="small" sx={{ color: '#00796b' }} />
                          </IconButton>
                        </Tooltip>
                      </Box>

                      {bmChartData?.data.map((data) => {
                        return (
                          <ChatBarChart
                            key={data.kpiName}
                            categories={bmChartData.headers}
                            data={data.chartData}
                            barColor="#FFF"
                            chartTitle={data.kpiName}
                            chartType="bm"
                            chartSubTitle={data.kpiUnit}
                          />
                        );
                      })}
                    </>
                  )}
                </span>
              )}
            </>
          )}
        </>
      ) : (
        <>
          <span className={classes.message_text}>{sanitizedHTML}</span>
          <div className={classes.chat_icon_wrapper}>
            <Person2Icon
              fontSize="medium"
              sx={{ color: '#00796b' }}
              className={classes.user_icon}
              aria-label="User Icon"
            />
          </div>
        </>
      )}
      {/* Show BarChartIcon conditionally */}
    </div>
  );
};

export default ChatMessage;
