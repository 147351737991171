import { rebexApi } from './baseRebexEndpoints';
import type { IChatbotUploadData, IChatResponse, IDropdownOptionsResponse } from '../../types/ChatBot'; // Import the interface for type safety

// interface IChatbotUploadData {
//   fileData: File;
//   isClientData: string;
//   clientName?: string | null;
//   surveyModuleName?: string | null;
// }

interface IAPIStatusResponse {
  message: string;
}

export const chatbotApi = rebexApi.injectEndpoints({
  endpoints: (builder) => ({
    // Send a message to the chatbot and expect an IChatResponse
    sendMessage: builder.mutation<IChatResponse, { message: any }>({
      query: (payload) => ({
        url: '/chatbot/newrequest',
        method: 'POST',
        body: payload, // Send the message in the request body
      }),
    }),
    // Fetch a list of clients, returning a typed IDropdownOptionsResponse
    getClients: builder.query<IDropdownOptionsResponse, null>({
      query: () => ({
        url: '/chatbot/get_clients',
        method: 'GET',
      }),
    }),
    // Upload data to the server, expecting a string response
    uploadData: builder.mutation<IAPIStatusResponse, IChatbotUploadData>({
      query: ({ fileData, isClientData, clientName = '', surveyModuleName = '' }) => {
        const formData = new FormData();
        formData.append('excel_file', fileData);
        formData.append('is_client_data', String(isClientData));
        formData.append('client_name', clientName ?? '');
        formData.append('survey_module_name', surveyModuleName ?? '');

        return {
          url: '/chatbot/uploadData',
          method: 'POST',
          body: formData,
          contentType: 'multipart/form-data',
        };
      },
    }),
  }),
});

// Export hooks for usage in components
export const { useSendMessageMutation, useGetClientsQuery, useUploadDataMutation } = chatbotApi;
