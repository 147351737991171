import React, { Fragment, useRef, useEffect } from 'react';
import classes from './ChatBox.module.css';
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import chatBot from '../../../../assets/svg/chatBot.svg';
import ChatMessage from './ChatMessage';
import {
  useSendMessageMutation,
  useGetClientsQuery,
  useUploadDataMutation,
} from '../../../../utils/redux/endpoints/chatbotEndpoints';
import {
  type IDataUploadFormValues,
  type IBMChartRawData,
  type IChat,
  type IChatResponse,
  type IKPIData,
  type IChatbotUploadData,
} from '../../../../utils/types/ChatBot';
import ThumbsUpDownRoundedIcon from '@mui/icons-material/ThumbsUpDownRounded';
import BarChartRoundedIcon from '@mui/icons-material/BarChartRounded';
import QuestionAnswerRoundedIcon from '@mui/icons-material/QuestionAnswerRounded';
import EmojiEventsRoundedIcon from '@mui/icons-material/EmojiEventsRounded';
import GroupRoundedIcon from '@mui/icons-material/GroupRounded';
import { styled } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import benchmarkIcon from '../../../../assets/svg/benchmark.svg';
import surveyIcon from '../../../../assets/svg/survey.svg';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import SettingsIcon from '@mui/icons-material/Settings';
import FileUpload from './FileUpload';

interface IChatBoxProps {
  setIsChatDialogOpen: (value: boolean) => void;
  setIsChatBoxFullScreen: (value: boolean) => void;
  isChatBoxFullScreen: boolean;
}

const channelUsageQuestions: string[] = [
  '% of customers who use Mobile banking app more than once a week in Germany?',
  'What % of Low income Male customers using ATM globally are under 40?',
  'What are the top 3 most popular channels used by customers in Germany under the age of 35?',
  'Which bank has the highest proportion of customers using mobile app in Spain?',
  'Draw me a chart for users across different age groups in France',
  'Create a chart of branch usage in Germany',
];
const benchmarkingQuestions: string[] = [
  "How does my bank's customer acquisition rate compare to the industry average?",
  "What are the trends in my bank's revenue per customer over the last three years?",
  "Can you provide insights into my bank's cost to income ratio for 2023?",
  'How is my bank performing in terms of digital sales compared to branch sales?',
  'What is the attrition rate for affluent customers at my bank?',
  'How does the average balance per customer with a savings account compare to peers?',
  'Can you give me a breakdown of the personnel cost per FTE at my bank?',
  "What percentage of my bank's customers are active in digital channels?",
  'How does the net interest income per customer for mass market compare to affluent customers?',
  "What are the key performance indicators for my bank's investment products?",
  'How is my bank performing on costs?',
];

const ChatBox: React.FC<IChatBoxProps> = ({ setIsChatDialogOpen, setIsChatBoxFullScreen, isChatBoxFullScreen }) => {
  const [message, setMessage] = React.useState<string>('');
  const [chatMessages, setChatMessages] = React.useState<any[]>([]);
  const [channelUsageChatMessages, setChannelUsageChatMessages] = React.useState<any[]>([]);
  const [benchmarkingChatMessages, setBenchmarkingChatMessages] = React.useState<any[]>([]);
  const chatEndRef = useRef<HTMLDivElement | null>(null);
  const [isChatLoading, setIsChatLoading] = React.useState<boolean>(false);
  const [sendMessage] = useSendMessageMutation(); // Removed unused vars isLoading and error
  const [uploadData] = useUploadDataMutation();

  const { data: clientData } = useGetClientsQuery(null);
  let typingInterval: NodeJS.Timeout | null = null;
  const [chatTopic, setChatTopic] = React.useState<string>('');
  const [isChatMode, setISChatMode] = React.useState<boolean>(false);
  const [chatOptions, setChatOptions] = React.useState<string[] | any>([]);
  const [selectedOptions, setSelectedOptions] = React.useState<Set<string>>(new Set());
  const [showAll, setShowAll] = React.useState<boolean>(false);
  const displayedOptions = showAll ? chatOptions : chatOptions.slice(0, 10);
  const [isShowConfiguration, setIsShowConfiguration] = React.useState<boolean>(false);
  const [isConfDialogOpen, setIsConfDialogOpen] = React.useState<boolean>(false);
  const [formValues, setFormValues] = React.useState<IDataUploadFormValues>({
    dataType: null,
    clientName: '',
    surveyModuleName: '',
    file: null,
  });

  useEffect(() => {
    const userInfo = localStorage.getItem('persist:root');
    if (userInfo !== null && userInfo !== undefined) {
      const auth: string = JSON.parse(userInfo).auth;
      const authData = auth !== undefined ? JSON.parse(auth) : null;
      console.log(authData);
      if (authData !== null && authData?.userDetails?.roles.includes('admin') === true) {
        setIsShowConfiguration(true);
      }
    }

    const tmpChannelChats = sessionStorage.getItem('channelUsageChatMessages');
    const tmpBenchmarkingChats = sessionStorage.getItem('benchmarkingChatMessages');
    if (tmpChannelChats !== null) {
      setChannelUsageChatMessages(JSON.parse(tmpChannelChats) as any[]);
    }
    if (tmpBenchmarkingChats !== null) {
      setBenchmarkingChatMessages(JSON.parse(tmpBenchmarkingChats) as any[]);
    }
  }, []);

  // Save messages to session storage whenever chatMessages changes
  useEffect(() => {
    if (channelUsageChatMessages.length > 0) {
      sessionStorage.setItem('channelUsageChatMessages', JSON.stringify(channelUsageChatMessages));
    }
    if (benchmarkingChatMessages.length > 0) {
      sessionStorage.setItem('benchmarkingChatMessages', JSON.stringify(benchmarkingChatMessages));
    }
    scrollToBottom();
  }, [channelUsageChatMessages, benchmarkingChatMessages]);

  useEffect(() => {
    setChatMessages([]);
    if (chatTopic === '') {
      setChatMessages([...benchmarkingChatMessages]);
    } else if (chatTopic === '1') {
      setChatMessages([...channelUsageChatMessages]);
    }
  }, [chatTopic]);

  const MaterialUISwitch = styled(Switch)(({ theme }) => ({
    width: 62,
    height: 34,
    padding: 7,
    '& .MuiSwitch-switchBase': {
      margin: 1,
      padding: 0,
      transform: 'translateX(6px)',
      '&.Mui-checked': {
        color: '#fff',
        transform: 'translateX(22px)',
        '& .MuiSwitch-thumb:before': {
          backgroundImage: `url(${benchmarkIcon})`,
        },
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: '#00796b',
          ...theme.applyStyles('light', {
            backgroundColor: '#00796b',
          }),
        },
      },
    },
    '& .MuiSwitch-thumb': {
      backgroundColor: '#001e3c',
      width: 32,
      height: 32,
      '&::before': {
        content: "''",
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundImage: `url(${surveyIcon})`,
      },
      ...theme.applyStyles('dark', {
        backgroundColor: '#003892',
      }),
    },
    '& .MuiSwitch-track': {
      opacity: 1,
      backgroundColor: '#00796b',
      borderRadius: 20 / 2,
      ...theme.applyStyles('light', {
        backgroundColor: '#00796b',
      }),
    },
  }));

  const toggleShowAll = () => {
    setShowAll((prev) => !prev);
  };

  const updateChatChannel = (chats: any) => {
    if (chatTopic === '1') {
      setChannelUsageChatMessages((prev) => [...prev, chats]);
    } else if (chatTopic === '') {
      setBenchmarkingChatMessages((prev) => {
        console.log(chats);
        return [...prev, chats];
      });
    }
  };

  const handleOptionSelection = (option: string) => {
    setSelectedOptions((prev: Set<string>) => {
      const newSet = new Set(prev);
      if (newSet.has(option)) {
        newSet.delete(option); // Remove the option if it already exists
      } else {
        newSet.add(option); // Add the option if it doesn't exist
      }
      setMessage(Array.from(newSet).join(', '));
      return newSet;
    });
  };

  const sendMessageHandler = async (event: React.FormEvent) => {
    event.preventDefault();
    if (message.trim() === '') return;

    // Add user's message
    setChatMessages([...chatMessages, { id: 'user', message }]);
    updateChatChannel({ id: 'user', message });
    setMessage('');
    setIsChatLoading(true);

    // Add "genie" placeholder message
    setChatMessages((prevMessages) => [...prevMessages, { id: 'genie', message: 'Evaluating your query' }]);

    // Start cycling text messages
    startGenieTyping();

    // Perform the API call
    if (chatOptions.length === 0) {
      await postNewMessageRequest(message);
    } else if (chatOptions.length > 0) {
      const optionList = message.split(',').map((item) => item.trim());
      setChatOptions([]);
      await postNewMessageRequest(optionList);
      setSelectedOptions(new Set());
    }
  };

  const postNewMessageRequest = async (message: string | string[]) => {
    try {
      const payload = { message, topic: chatTopic };
      const response: IChatResponse = await sendMessage(payload).unwrap();

      // Stop typing effect when response is received
      stopGenieTyping();
      let newChat: IChat;
      if (response?.chart?.is_chart === true) {
        const { categories, values } = response.chart.data;
        const chartMessage = response.chart?.message !== undefined ? response.chart?.message : 'Here is your chart';
        newChat = {
          id: 'genie',
          message: response.message ?? '',
          is_chart: true,
          chart: { categories, values, chartMessage },
        };
        setChatMessages((prevMessages) => [...prevMessages.slice(0, -1), newChat]);
      } else {
        newChat = {
          id: 'genie',
          message: response.message ?? '',
          is_chart: false,
        };

        if (Object.keys(response).includes('data') && response.data !== undefined && response.data?.length > 0) {
          const rawKpiData: IKPIData[] = response.data;
          const headers: string[] = generateHeaders(rawKpiData);
          const chartData: IBMChartRawData[] = generateDataForAllKPIs(rawKpiData);
          console.log(headers);
          console.log(chartData);
          newChat.bmChartData = { headers, data: chartData };
        }
        setChatMessages((prevMessages) => [...prevMessages.slice(0, -1), newChat]);
        if (response.kpi_list !== undefined) {
          setChatOptions(response.kpi_list);
        }
      }
      updateChatChannel(newChat);
      setIsChatLoading(false);
    } catch (err) {
      console.error('Failed to send message:', err);
      const newChat: IChat = { id: 'genie', message: 'Something went wrong. Please try again.', is_chart: false };
      // Stop typing effect if there’s an error
      stopGenieTyping();
      setChatMessages((prevMessages) => [...prevMessages.slice(0, -1), newChat]);
      updateChatChannel(newChat);
      setIsChatLoading(false);
    }
  };

  const startGenieTyping = () => {
    const typingMessages = ['Evaluating your query', 'Please hold on...'];
    let currentIndex = 0;

    // Start cycling through messages
    typingInterval = setInterval(() => {
      setChatMessages((prevMessages) => {
        const updatedMessages = [...prevMessages];
        updatedMessages[updatedMessages.length - 1] = {
          ...updatedMessages[updatedMessages.length - 1],
          message: typingMessages[currentIndex],
        };
        return updatedMessages;
      });

      // Cycle through the messages
      currentIndex = (currentIndex + 1) % typingMessages.length;
    }, 700); // Change message every 2 seconds
  };

  const stopGenieTyping = () => {
    if (typingInterval !== null) {
      clearInterval(typingInterval);
      typingInterval = null;
    }
  };

  const scrollToBottom = () => {
    chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const chatToggleHandler = (value: boolean) => {
    setISChatMode(value);
    setChatTopic('');
    setMessage('');
    setChatOptions([]);
  };

  const generateHeaders = (data: IKPIData[]): string[] => {
    // Extract unique peer group names
    const peerGroups = Array.from(new Set(data.map((item) => item.peer_group_name)));
    const clientName: string = data.length > 0 ? data[0].client_name : 'Client';

    // Generate headers
    const headers: string[] = [];
    peerGroups.forEach((peerGroup) => {
      headers.push(`${peerGroup} Best Quartile`, `${peerGroup} Average`);
    });

    // Add client name as the last header
    headers.push(clientName);

    return headers;
  };

  const generateDataForAllKPIs = (data: IKPIData[]): IBMChartRawData[] => {
    // Extract unique KPI names
    const kpiNames = Array.from(new Set(data.map((item) => item.kpi_name)));

    return kpiNames.map((kpiName) => {
      // Filter data for the current KPI
      const kpiData = data.filter((item) => item.kpi_name === kpiName);

      // Extract unique peer group names
      const peerGroups = Array.from(new Set(kpiData.map((item) => item.peer_group_name)));

      // Get the unit for the KPI (assuming it's the same across entries for the same KPI)
      const kpiUnit = kpiData.length > 0 ? kpiData[0].unit : '';

      // Generate chart data in the required order
      const chartData: number[] = [];
      peerGroups.forEach((peerGroup) => {
        const groupData: IKPIData | undefined = kpiData.find((item) => item.peer_group_name === peerGroup);

        if (groupData !== undefined && groupData !== null) {
          const bestQuartile =
            groupData.best_quartile !== null && groupData.best_quartile !== undefined
              ? Number(groupData.best_quartile)
              : 0;
          const average =
            groupData.average !== null && groupData.average !== undefined ? Number(groupData.average) : 0;

          chartData.push(
            !isNaN(bestQuartile) ? bestQuartile : 0, // Safely convert to number or default to 0
            !isNaN(average) ? average : 0, // Safely convert to number or default to 0
          );
        } else {
          chartData.push(0, 0); // Default values if data is missing
        }
      });

      // Add client value
      const clientValue =
        kpiData.length > 0 && kpiData[0].client_value !== null && kpiData[0].client_value !== undefined
          ? Number(kpiData[0].client_value)
          : 0;

      chartData.push(!isNaN(clientValue) ? clientValue : 0); // Safely convert to number or default to 0

      return {
        kpiName: kpiName,
        chartData: chartData,
        kpiUnit: kpiUnit, // Include the unit in the output
      };
    });
  };

  const handleFormChange = (key: string, value: string | null) => {
    setFormValues((prev: IDataUploadFormValues) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleUploadData = async () => {
    if (clientData?.clients !== undefined && clientData.clients.length > 0) {
      setIsConfDialogOpen(true);
    }
  };

  const saveUploadedData = async () => {
    try {
      if (formValues.dataType !== null && formValues.clientName !== null && formValues.file !== null) {
        const payload: IChatbotUploadData = {
          fileData: formValues.file,
          isClientData: (formValues.clientName !== null).toString(),
          clientName: formValues.clientName,
          surveyModuleName: formValues.surveyModuleName ?? '',
        };

        const response = await uploadData(payload).unwrap();
        console.log('Upload successful:', response);
      } else {
        console.error('Missing required form values. Cannot upload data.');
      }
    } catch (error) {
      console.error('Error uploading data:', error);
    }
  };

  return (
    <Fragment>
      <DialogTitle
        sx={{
          backgroundColor: '#00796b',
          color: '#fff',
          padding: '0.7rem 1rem !important',
          display: 'flex',
          gap: '.3rem',
          justifyContent: 'space-between',
          alignItems: 'center',
          boxShadow: '5px 5px 1px #000 !important',
        }}
      >
        <Typography variant="h6">Chat with Genie </Typography>
        <Box className={classes.switch_wrapper}>
          <Typography variant="caption" sx={{ fontSize: '0.8em' }}>
            Consumer Survey Queries
          </Typography>
          <FormControlLabel
            control={
              <MaterialUISwitch
                sx={{ m: 1 }}
                checked={isChatMode && chatTopic === ''}
                onChange={(event) => {
                  chatToggleHandler(event.target.checked);
                }}
              />
            }
            label="Benchmarked Results Queries"
          />
        </Box>
        <Box>
          <Tooltip title="Configure Datasets" placement="top">
            <IconButton
              onClick={() => {
                void handleUploadData();
              }}
              sx={{ color: '#fff' }}
            >
              {isShowConfiguration ? <SettingsIcon /> : <></>}
            </IconButton>
          </Tooltip>
          <Tooltip title="Full Screen Toggle" placement="top">
            <IconButton
              onClick={() => {
                setIsChatBoxFullScreen(!isChatBoxFullScreen);
              }}
              sx={{ color: '#fff' }}
            >
              {isChatBoxFullScreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
            </IconButton>
          </Tooltip>
          <Tooltip title="Close" placement="top">
            <IconButton
              onClick={() => {
                setIsChatDialogOpen(false);
              }}
              sx={{ color: '#fff' }}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </DialogTitle>
      {isChatMode ? (
        <DialogContent className={classes.content_wrapper}>
          {chatMessages.length > 0 ? (
            <div className={classes.chat_wrapper}>
              {chatMessages.map((chat, index) => (
                <ChatMessage
                  key={index} // Added key prop
                  id={chat.id}
                  message={chat.message}
                  isLastIndex={index === chatMessages.length - 1}
                  isChatLoading={isChatLoading}
                  isChart={chat.is_chart} // Use is_chart, not isChart
                  chart={
                    chat.chart !== undefined
                      ? {
                          categories: chat.chart.categories,
                          values: chat.chart.values,
                          chartMessage: chat.chart.chartMessage,
                        }
                      : undefined
                  }
                  mode={chatTopic === '' ? 'bm' : 'srv'}
                  bmChartData={chat.bmChartData} // Added mode prop, bm stands for benchmarking and srv stands for survey
                />
              ))}

              <div ref={chatEndRef} />
            </div>
          ) : (
            <section className={classes.no_chat_data}>
              <img src={chatBot} alt="chat icon" height="60px" width="60px" />
              <h3>
                Hi, I am Genie. I can help you with{' '}
                {chatTopic !== '' ? 'Consumer Survey insights' : 'Benchmarked KPI Comparision with Peers & Insights'}
              </h3>
              <ul className={classes.question_card}>
                <Typography sx={{ textAlign: 'center', marginBottom: '1rem' }}>Ask me Anything like:</Typography>
                {chatTopic === '1' &&
                  channelUsageQuestions.map((question, index) => (
                    <li
                      key={index} // Added key prop
                      className={classes.list_item}
                      onClick={() => {
                        setMessage(question);
                      }}
                    >
                      {question}
                    </li>
                  ))}
                {chatTopic === '' &&
                  benchmarkingQuestions.map((question, index) => (
                    <li
                      key={index} // Added key prop
                      className={classes.list_item}
                      onClick={() => {
                        setMessage(question);
                      }}
                    >
                      {question}
                    </li>
                  ))}
              </ul>
            </section>
          )}
        </DialogContent>
      ) : (
        <DialogContent sx={{ padding: 0, display: 'flex', gap: '1rem' }}>
          {!isChatMode && chatTopic === '' ? (
            <Box className={classes.topic_nav_wrapper}>
              <Box
                className={classes.topic_nav}
                onClick={() => {
                  setChatTopic('1');
                  setISChatMode(true);
                }}
              >
                <section className={classes.nav_icon}>
                  <BarChartRoundedIcon sx={{ fontSize: '5em' }} />
                </section>
                <section className={classes.nav_title}>Channel Usage</section>
              </Box>
              <Box className={classes.topic_nav}>
                <section className={classes.nav_icon}>
                  <ThumbsUpDownRoundedIcon sx={{ fontSize: '5em' }} />
                </section>
                <section className={classes.nav_title}>Channel Satisfaction</section>
              </Box>
              <Box className={classes.topic_nav}>
                <section className={classes.nav_icon}>
                  <EmojiEventsRoundedIcon sx={{ fontSize: '5em' }} />
                </section>
                <section className={classes.nav_title}>Net Promoter Score</section>
              </Box>
              <Box className={classes.topic_nav}>
                <section className={classes.nav_icon}>
                  <QuestionAnswerRoundedIcon sx={{ fontSize: '5em' }} />
                </section>
                <section className={classes.nav_title}>Drivers of NPS</section>
              </Box>
              <Box className={classes.topic_nav}>
                <section className={classes.nav_icon}>
                  <GroupRoundedIcon sx={{ fontSize: '5em' }} />
                </section>
                <section className={classes.nav_title}>Product ownership</section>
              </Box>
            </Box>
          ) : (
            <></>
          )}
        </DialogContent>
      )}

      {isChatMode ? (
        <DialogActions className={classes.action_wrapper}>
          <form className={classes.form_wrapper} onSubmit={sendMessageHandler}>
            <div className={classes.option_wrapper}>
              {displayedOptions.map((kpi: string, i: number) => (
                <Chip
                  key={i}
                  sx={{
                    backgroundColor: selectedOptions.has(kpi) ? '#00796b' : 'default',
                    color: selectedOptions.has(kpi) ? '#fff' : '#000',
                  }}
                  label={kpi}
                  variant="outlined"
                  onClick={() => {
                    handleOptionSelection(kpi);
                  }}
                />
              ))}
              {chatOptions.length > 10 && (
                <Typography className={classes.expand_options} onClick={toggleShowAll}>
                  {showAll ? 'Show Less' : 'Show More...'}
                </Typography>
              )}
            </div>
            <div className={classes.input_message_wrapper}>
              <TextField
                value={message}
                disabled={isChatLoading || chatOptions.length > 0}
                fullWidth
                size="small"
                focused
                placeholder="Ask me anything"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setMessage(event.target.value);
                }}
              />
            </div>

            {chatOptions.length > 0 && (
              <Tooltip title="Cancel Request" placement="top">
                <IconButton
                  size="small"
                  color="error"
                  onClick={() => {
                    setChatOptions([]);
                    setSelectedOptions(new Set());
                    setIsChatLoading(false);
                    setMessage('');
                  }}
                  sx={{
                    backgroundColor: (theme) => theme.palette.error.main, // Use the error color from the theme
                    color: '#fff', // Set the icon color to white for contrast
                    '&:hover': {
                      backgroundColor: (theme) => theme.palette.error.dark, // Darker shade on hover
                    },
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            )}

            <div className={classes.chat_action_wrapper}>
              <IconButton
                sx={{ color: '#00796b' }}
                disabled={message.trim() === ''}
                type="submit"
                aria-label="Send Message"
              >
                <SendIcon />
              </IconButton>
            </div>
          </form>
        </DialogActions>
      ) : (
        <DialogActions sx={{ height: '3.5rem' }} className={classes.action_wrapper}></DialogActions>
      )}
      <Dialog
        fullWidth
        maxWidth="xs"
        open={isConfDialogOpen}
        onClose={() => {
          setIsConfDialogOpen(false);
        }}
      >
        <DialogTitle>Upload Data</DialogTitle>
        <DialogContent sx={{ minHeight: '5rem' }}>
          <FormControl fullWidth size="small" sx={{ marginTop: '0.5rem', marginBottom: '0.8rem' }}>
            <InputLabel id="demo-simple-select-label">Select Data Type</InputLabel>
            <Select
              size="small"
              required
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={formValues.dataType}
              onChange={(event) => {
                handleFormChange('dataType', event.target.value?.toString() ?? '');
              }}
              label="Select Data Type"
            >
              <MenuItem value={'BMD'}>Benchmark Data</MenuItem>
              <MenuItem value={'CSD'}>Consumer Survey Data</MenuItem>
            </Select>
          </FormControl>
          {formValues.dataType === 'BMD' && (
            <FormControl fullWidth size="small" sx={{ marginTop: '0.5rem', marginBottom: '0.8rem' }}>
              <Autocomplete
                size="small"
                value={formValues.clientName}
                disablePortal={false}
                onChange={(event, value: string | null) => {
                  handleFormChange('clientName', value);
                }}
                options={clientData?.clients ?? []}
                fullWidth
                renderInput={(params) => <TextField {...params} label="Select Client" />}
              />
            </FormControl>
          )}
          {formValues.dataType === 'CSD' && (
            <FormControl fullWidth size="small" sx={{ marginTop: '0.5rem', marginBottom: '0.5rem' }}>
              <InputLabel id="demo-simple-select-label">Select Survey Module</InputLabel>
              <Select
                size="small"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={formValues.surveyModuleName}
                onChange={(event) => {
                  handleFormChange('surveyModuleName', event.target.value?.toString() ?? '');
                }}
                label="Select Survey Module"
              >
                <MenuItem value={'CU'}>Channel Usage</MenuItem>
                <MenuItem value={'CS'}>Channel Satisfaction</MenuItem>
                <MenuItem value={'NPS'}>Net Promotor Score</MenuItem>
                <MenuItem value={'DNPS'}>Drivers of NPS</MenuItem>
                <MenuItem value={'PO'}>Product Ownership</MenuItem>
              </Select>
            </FormControl>
          )}
          {(formValues.clientName !== null || formValues.surveyModuleName !== null) && (
            <FileUpload formValues={formValues} setFormValues={setFormValues} />
          )}
        </DialogContent>
        <DialogActions>
          <Button
            color="error"
            variant="text"
            onClick={() => {
              setIsConfDialogOpen(false);
            }}
          >
            Close
          </Button>
          <Button
            sx={{ color: '#00796b' }}
            variant="text"
            onClick={() => {
              setIsConfDialogOpen(false);
              void saveUploadedData();
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default ChatBox;
