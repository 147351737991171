import React from 'react';
import DOMPurify from 'dompurify';

interface RenderHTMLProps {
  htmlString: string; // Define the expected type for the htmlString prop
}

const RenderHTML: React.FC<RenderHTMLProps> = ({ htmlString }) => {
  // Sanitize the input HTML string
  const sanitizedHTML = DOMPurify.sanitize(htmlString);

  return (
    <div
      dangerouslySetInnerHTML={{ __html: sanitizedHTML }}
      style={{ whiteSpace: 'pre-wrap' }} // Ensure line breaks and whitespace are preserved
    ></div>
  );
};

export default RenderHTML;
